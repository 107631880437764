var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTableAdvance',{attrs:{"items":_vm.resourceList,"fields":_vm.fields,"noFilter":"","store":"auth.resources","resource":""},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.name,"noTranslate":""}})],1)]}},{key:"create",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},_vm._l((item.scope),function(scope){return _c('div',{key:scope.id},[_c('TInputCheckbox',{attrs:{"disabled":_vm.readonly || _vm.updating,"checked":scope.permission.create},on:{"change":function($event){return _vm.change(item.id, 'create', scope.id)}}})],1)}),0)]}},{key:"read",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},_vm._l((item.scope),function(scope){return _c('div',{key:scope.id},[_c('TInputCheckbox',{attrs:{"disabled":_vm.readonly || _vm.updating,"checked":scope.permission.read},on:{"change":function($event){return _vm.change(item.id, 'read', scope.id)}}})],1)}),0)]}},{key:"update",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},_vm._l((item.scope),function(scope){return _c('div',{key:scope.id},[_c('TInputCheckbox',{attrs:{"disabled":_vm.readonly || _vm.updating,"checked":scope.permission.update},on:{"change":function($event){return _vm.change(item.id, 'update', scope.id)}}})],1)}),0)]}},{key:"delete",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},_vm._l((item.scope),function(scope){return _c('div',{key:scope.id},[_c('TInputCheckbox',{attrs:{"disabled":_vm.readonly || _vm.updating,"checked":scope.permission.delete},on:{"change":function($event){return _vm.change(item.id, 'delete', scope.id)}}})],1)}),0)]}},{key:"scope",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.scope),function(scope){return _c('div',{key:scope.id},[_c('TMessage',{attrs:{"content":scope.name,"bold":"","noTranslate":scope.noTranslate || false}})],1)}),0)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }